// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { useEffect, useState } from "react";
import { FormRow, RadioGroup } from "components/form";
import SaveResult from "components/form/SaveResult";
import { isPresetTextItem } from "lib/content";
import { useForm } from "react-hook-form";
import {
  ContentItem,
  ContentItemExtended,
  ContentItemParams,
  FormSubmitData,
  TextEditForm,
} from "types/content";
import { MutationResult } from "types/mutation";
import ConfirmDelete from "./ConfirmDelete";
import { createContentItem2, updateContentItem2 } from "lib/gf-api/content-api";
import { useApiHelper } from "lib/gf-api/api-react";
import { ApiResponse } from "types/api";
import FormFields from "./text/FormFields";
import SubmitButtonRow from "components/admin/ui/SubmitButtonRow";
import HollowButton from "components/admin/ui/HollowButton";
import { TrashIcon } from "@heroicons/react/24/outline";

interface Props {
  addingAttrs?: Partial<ContentItemParams>;
  contentItem?: ContentItemExtended;
  initialValues?: Partial<TextEditForm>;
  mutationResult?: MutationResult | undefined | null;
  onClose?: () => void;
  onDelete?: () => void;
  onSave?: (contentItem: ApiResponse<ContentItem>) => void;
  saving?: boolean;
}

interface SaveParams {
  item: Partial<ContentItemParams>;
  buttonItem: null | Partial<ContentItemParams>;
  photoItem: null | Partial<ContentItemParams>;
}

const defaultValues: TextEditForm = {
  buttonItem: {
    id: "",
    label: "",
    uri: "",
  },
  content: "",
  contentType: "text/markdown",
  internalName: "",
  isPublished: true,
  photoItem: {
    content: "",
    id: null,
    photoId: null,
  },
  photoUrl: null,
  plurality: "",
  slug: null,
  subType: null,
  title: "",
  uri: "",
};

export default function TextEdit(props: Props) {
  const updateCall = useApiHelper<ContentItem>();
  const createCall = useApiHelper<ContentItem>();
  const { contentItem, mutationResult, saving } = props;
  const addingAttrs = props.addingAttrs || {};

  let initialValues1 = {
    ...defaultValues,
    ...(props.initialValues || {}),
    ...addingAttrs,
  };
  let initialValues: TextEditForm = {
    ...initialValues1,
    uri: initialValues1.uri || "",
  };
  if (initialValues.subType === "page") {
    initialValues = { ...initialValues, uri: "/resources/new-page" };
  }

  const photoItem = contentItem?.children.find((ch) => ch.baseType === "photo");
  const buttonItem = contentItem?.children.find((ch) => ch.baseType === "nav");

  const hookForm = useForm<TextEditForm>({
    mode: "all",
    defaultValues: initialValues,
  });
  const { handleSubmit, register, setValue, watch } = hookForm;

  const [deleting, setDeleting]: [boolean, (v: boolean) => void] = useState(
    false as boolean
  );

  function onCancelClick() {
    props.onClose && props.onClose();
  }

  async function onSubmit(data: Partial<TextEditForm>) {
    let {
      isPublished,
      photoItem: photoItemParams1,
      buttonItem: buttonItemParams1,
      photoUrl,
      ...params1
    } = data;
    let params = params1 as Partial<ContentItemParams>;

    if (contentItem) {
      params = { ...params, id: contentItem.id };
    }

    if (isPublished) {
      params = { ...params, status: "published" };
    } else {
      params = { ...params, status: "draft" };
    }

    let photoItemParams = null;
    if (photoItemParams1 && !photoItemParams1.id) {
      let { id, ...photoItemParams2 } = photoItemParams1;
      photoItemParams = photoItemParams2;
    } else if (photoItemParams1) {
      photoItemParams = photoItemParams1;
    }

    let buttonItemParams = null;
    if (buttonItemParams1 && !buttonItemParams1.id) {
      let { id, ...buttonItemParams2 } = buttonItemParams1;
      buttonItemParams = buttonItemParams2;
    } else if (buttonItemParams1) {
      buttonItemParams = buttonItemParams1;
    }

    if (addingAttrs) {
      params = { ...addingAttrs, ...params };
    }

    const data2: SaveParams = {
      item: params,
      photoItem: photoItemParams,
      buttonItem: buttonItemParams,
    };

    let params2 = translateParams(data2);

    let result;
    if (contentItem?.id) {
      params2 = { ...params2, id: contentItem.id };
      result = await updateCall.wrapCall((apiProps) =>
        updateContentItem2(apiProps, params2)
      );
    } else {
      result = await createCall.wrapCall((apiProps) =>
        createContentItem2(apiProps, params2)
      );
    }

    props.onSave && props.onSave(result);
  }

  function translateParams(data: FormSubmitData): Partial<ContentItemParams> {
    let { item: params } = data;

    params = {
      baseType: "text",
      plurality: "item",
      ...params,
    };

    if (params.plurality === "item") {
      let children: Partial<ContentItemParams>[] = [];
      let { buttonItem, photoItem } = data;
      if (photoItem && (photoItem.photoId || photoItem.content)) {
        photoItem = { ...photoItem, baseType: "photo" };
        children = [...children, photoItem];
      }
      if (buttonItem && buttonItem.label) {
        buttonItem = { ...buttonItem, baseType: "nav" };
        children = [...children, buttonItem];
      }
      params = { ...params, children };
    }

    return params;
  }

  function onPerformDelete() {
    setDeleting(false);
    props.onDelete && props.onDelete();
  }

  useEffect(() => {
    if (contentItem) {
      setValue("content", contentItem.content || "");
      setValue("contentType", contentItem.contentType || "text/markdown");
      setValue("internalName", contentItem.internalName || "");
      setValue("isPublished", contentItem.status === "published");
      setValue("plurality", contentItem.plurality);
      setValue("slug", contentItem.slug);
      setValue("subType", contentItem.subType);
      setValue("title", contentItem.title || "");
      setValue("uri", contentItem.uri || "");
      if (photoItem) {
        setValue("photoItem.id", photoItem.id);
        setValue("photoItem.photoId", photoItem.photoId);
        setValue("photoItem.content", photoItem.content || "");
        setValue("photoUrl", photoItem.photo?.url || null);
      }
      if (buttonItem) {
        setValue("buttonItem.id", buttonItem.id);
        setValue("buttonItem.label", buttonItem.label);
        setValue("buttonItem.uri", buttonItem.uri || "");
      }
    } else if (addingAttrs) {
      hookForm.reset({ ...addingAttrs, uri: addingAttrs.uri || "" });
    }
  }, [contentItem]);

  useEffect(() => {
    if (watch("subType") === "page") {
      setValue("plurality", "item");
    } else if (!watch("plurality")) {
      setValue("plurality", "list");
    }
  }, [watch("subType")]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 justify-between"
      >
        <SaveResult mutationResult={mutationResult} className="mb-4" />

        {watch("subType") ? (
          <FormFields contentItem={contentItem} hookForm={hookForm} />
        ) : (
          <FormRow>
            What type of content do you want to create?
            <RadioGroup
              radios={[
                { label: "Page", value: "page" },
                { label: "Posts", value: "post" },
              ]}
              register={register("subType")}
            />
          </FormRow>
        )}

        {watch("subType") && (
          <>
            <SaveResult mutationResult={mutationResult} className="mb-4" />
            <SubmitButtonRow
              cancel={{ onClick: () => onCancelClick() }}
              className="mb-4"
              saving={saving}
            >
              Save
            </SubmitButtonRow>
          </>
        )}
      </form>

      {contentItem && !isPresetTextItem(contentItem) && (
        <>
          <div className="flex justify-center">
            <HollowButton
              onClick={() => setDeleting(true)}
              colorStyle="warning"
            >
              <TrashIcon className="h-5 w-5" />
              Permanently Delete
            </HollowButton>
          </div>
          <ConfirmDelete
            deleting={deleting}
            onClose={() => setDeleting(false)}
            onDelete={onPerformDelete}
            prompt="Are you sure you want to delete this item?"
            themeArea="admin"
          />
        </>
      )}
    </>
  );
}
